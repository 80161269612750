import { FC, ReactNode, useCallback } from "react";
import {
  FallbackProps,
  ErrorBoundary as ReactErrorBoundary
} from "react-error-boundary";

import { ErrorBoundaryComponentType } from "./error.utils";
import { ErrorBoundaryModal } from "./ErrorBoundaryModal";
import { ErrorBoundaryPage } from "./ErrorBoundaryPage";

type ErrorBoundaryType = "modal" | "page";
// type ErrorBoundaryType = "modal" | "page" | "modal-full-page";

const ErrorBoundaryComponentMap: {
  [key in ErrorBoundaryType]: ErrorBoundaryComponentType;
} = {
  modal: ErrorBoundaryModal,
  page: ErrorBoundaryPage
  // "modal-full-page": ErrorBoundaryPage
};

export type UseErrorBoundaryParams = {
  /**
   * The title of an error that displays
   * in the fallback error component
   * @default "Uh oh, there was an error"
   */
  title?: string;
  /**
   * A small description that replaces the default description
   * @default "Something unexpected happened and it created an error. However, fear not as we're working on finding a solution for this. Our support team has been contacted and are already looking into it."
   */
  description?: string;
  /**
   * Indicate the type of error component
   * that should render when the error occurs
   * @default page
   */
  type?: ErrorBoundaryType;
};

/**
 * This hook returns a custom error boundary component that helps
 * in catching errors so we don't completly unload the app.
 */
export const useErrorBoundary = ({
  type = "page",
  title = "Uh oh, there was an error",
  description = "Something unexpected happened and it created an error. However, fear not as we're working on finding a solution for this. Our support team has been contacted and are already looking into it."
}: UseErrorBoundaryParams) => {
  const ErrorFallback = useCallback<FC<FallbackProps>>(
    (errorBoundaryProps) => {
      const Component = ErrorBoundaryComponentMap[type];
      return (
        <Component
          {...errorBoundaryProps}
          title={title}
          description={description}
        />
      );
    },
    [description, title, type]
  );

  const ErrorBoundary = useCallback<FC<{ children: ReactNode }>>(
    ({ children }) => {
      return (
        <ReactErrorBoundary FallbackComponent={ErrorFallback}>
          {children}
        </ReactErrorBoundary>
      );
    },
    [ErrorFallback]
  );

  return ErrorBoundary;
};
