import {
  Button,
  Icon,
  TypographyCopy,
  TypographyHeading,
  desktopStyles,
  makeColor,
  makeRem
} from "@machineq/elements";
import { AlertCircle } from "@machineq/icons";
import styled from "styled-components";

import { ErrorBoundaryComponentType } from "./error.utils";
import { ErrorCodeBlock } from "./ErrorCodeBlock";

const SDiv = styled.div`
  width: 100%;
  height: 100%;
  background: ${makeColor("white")};
  padding: ${makeRem(30)};
  overflow-y: auto;

  ${desktopStyles} {
    padding: 0 ${makeRem(60)};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > div {
    background: ${makeColor("white")};
    width: 100%;
    max-width: ${makeRem(600)};
  }
`;

export const ErrorBoundaryPage: ErrorBoundaryComponentType = ({
  error,
  resetErrorBoundary,
  title,
  description
}) => {
  return (
    <SDiv>
      <div
        style={{
          marginBottom: makeRem(32)
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: makeRem(20),
            marginTop: makeRem(32)
          }}
        >
          <Icon mqColor="danger" mqSize={32} mqTitle="error">
            <AlertCircle />
          </Icon>
          &nbsp;&nbsp;&nbsp;
          <TypographyHeading mqVariant="h5" mqNode="div" style={{}}>
            {title}
          </TypographyHeading>
        </div>
        <TypographyCopy> {description}</TypographyCopy>
        <br />
        <TypographyCopy>
          You can read more about the error below:
        </TypographyCopy>

        <ErrorCodeBlock message={error.message} stack={error.stack} />
        <br />
        <br />
        <Button
          onClick={resetErrorBoundary}
          mqColor="primary"
          mqVariant="contained"
        >
          Try again
        </Button>
      </div>
    </SDiv>
  );
};
