import { getRuntimeVariable } from "@pwa/lib/env";
import Head from "next/head";
import React, { FC, useMemo } from "react";

export type SEOBasic = {
  title?: string;
  description?: string;
  url?: string;
  image?: string;
  keywords?: string[];
};

export type SEOBasicExtra = {
  titleAlt: string;
  language: string;
  baseTitle: string;
};

export type SEOComplex = Record<string, unknown>;

const baseSeo: Required<SEOBasic & SEOBasicExtra> & SEOComplex = {
  baseTitle: "MachineQ",
  title: "Asset Track",
  titleAlt: "MachineQ",
  description: "Comcast MachineQ Asset Tracking",
  image: "src/images/machineq-logo.png",
  url: getRuntimeVariable("PWA_BROWSER_APP_HOSTNAME"),
  keywords: [],
  language: "en",
  basePath: "/"
};

/**
 * SEO Component design specifically to use the NextJS <Head />
 * component. This should be placed on every "NEXT" page since
 * this feature doesn't span multiple frameworks
 */
export const PageSEO: FC<React.PropsWithChildren<SEOBasic & SEOComplex>> = (
  dynamicSeo
) => {
  const metaTitle = useMemo(
    () => `${dynamicSeo.title || "MachineQ"} | ${baseSeo.baseTitle}`,
    [dynamicSeo.title]
  );

  const metaKeywords = useMemo(() => {
    const dynKeywords = dynamicSeo.keywords || [];
    return [...baseSeo.keywords, ...dynKeywords].join(", ");
  }, [dynamicSeo.keywords]);

  const metaCanonical = useMemo(() => {
    return `${baseSeo.url}${dynamicSeo.url}`;
  }, [dynamicSeo.url]);

  const metaDescription = useMemo(() => {
    return dynamicSeo.description || baseSeo.description;
  }, [dynamicSeo.description]);

  return (
    <Head>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta name="robots" content="noodp" />
      <meta name="image" content={dynamicSeo.image || baseSeo.image} />
      <meta name="keywords" content={metaKeywords} />
      <link rel="canonical" href={metaCanonical} />

      {/* SLACK */}
      <meta property="og:site_name" content={baseSeo.baseTitle} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content="/images/machine-q-meta.jpg" />
      <meta property="og:url" content="https://machineq.com/" />
    </Head>
  );
};
