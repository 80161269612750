import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeaderText,
  TypographyCopy
} from "@machineq/elements";

import { ErrorBoundaryComponentType } from "./error.utils";
import { ErrorCodeBlock } from "./ErrorCodeBlock";

export const ErrorBoundaryModal: ErrorBoundaryComponentType = ({
  error,
  resetErrorBoundary,
  title,
  description
}) => {
  return (
    <>
      <ModalHeader>
        <ModalHeaderText>{title}</ModalHeaderText>
      </ModalHeader>
      <ModalBody>
        <TypographyCopy> {description}</TypographyCopy>
        <br />
        <TypographyCopy>
          You can read more about the error below:
        </TypographyCopy>

        <ErrorCodeBlock message={error.message} stack={error.stack} />
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={resetErrorBoundary}
          mqColor="primary"
          mqVariant="contained"
        >
          Try again
        </Button>
      </ModalFooter>
    </>
  );
};
